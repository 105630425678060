import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import { AUTH_SET_TOKEN } from "@/store/actions/auth";

Vue.use(Router);

const routes = [
	//start
	{
		path: "/",
		redirect: "/auth/login",
	},
	//auth
	{
		path: "/auth",
		name: "Auth",
		component: () => import("./views/AuthLayout.vue"),
		meta: { requiresAuth: false },
		children: [
			{
				path: "login",
				name: "Login",
				component: () => import("./views/auth/Login.vue"),
				meta: { requiresAuth: false },
			},
		],
	},
	//pages
	{
		path: "/pages",
		name: "Pages",
		redirect: "/pages/widgets",
		component: () => import("./views/PagesLayout.vue"),
		meta: { requiresAuth: true },
		children: [
			//Widgets
			{
				path: "widgets",
				name: "Widgets",
				component: () => import("./views/pages/widgets/Widgets.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Виджеты",
							disabled: true,
						},
					],
				},
			},
			{
				path: "widgets/update/:id",
				name: "WidgetsUpdate",
				component: () => import("./views/pages/widgets/WidgetsForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Виджеты",
							disabled: false,
							href: "/#/pages/widgets",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "widgets/create",
				name: "WidgetsCreate",
				component: () => import("./views/pages/widgets/WidgetsForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Виджеты",
							disabled: false,
							href: "/#/pages/widgets",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			//Pages
			{
				path: "pages",
				name: "Pages",
				component: () => import("./views/pages/pages/Pages.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Мозаики",
							disabled: true,
						},
					],
				},
			},
			{
				path: "pages/update/:id",
				name: "PagesUpdate",
				component: () => import("./views/pages/pages/PagesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Мозаики",
							disabled: false,
							href: "/#/pages/pages",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "pages/create",
				name: "PagesCreate",
				component: () => import("./views/pages/pages/PagesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Мозаики",
							disabled: false,
							href: "/#/pages/pages",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			//Users
			{
				path: "users",
				name: "Users",
				component: () => import("./views/pages/users/Users.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Пользователи",
							disabled: true,
						},
					],
				},
			},
			{
				path: "users/update/:id",
				name: "UsersUpdate",
				component: () => import("./views/pages/users/UsersForm.vue"),
				meta: {
					requiresAuth: true,
					adminOnly: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Пользователи",
							disabled: false,
							href: "/#/pages/users",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "users/create",
				name: "UsersCreate",
				component: () => import("./views/pages/users/UsersForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					adminOnly: true,
					breadcrumb: [
						{
							text: "Пользователи",
							disabled: false,
							href: "/#/pages/users",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			//Regions
			{
				path: "regions",
				name: "Regions",
				component: () => import("./views/pages/regions/Regions.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Регионы",
							disabled: true,
						},
					],
				},
			},
			{
				path: "regions/update/:id",
				name: "RegionsUpdate",
				component: () => import("./views/pages/regions/RegionsForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Регионы",
							disabled: false,
							href: "/#/pages/regions",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "regions/create",
				name: "RegionsCreate",
				component: () => import("./views/pages/regions/RegionsForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Регионы",
							disabled: false,
							href: "/#/pages/regions",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path: "regions/districts/:id",
				name: "Districts",
				component: () => import("./views/pages/regions/Districts.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Регионы",
							disabled: false,
							href: "/#/pages/regions",
						},
						{
							text: "Области",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			//StatePrograms
			{
				path: "statePrograms",
				name: "StatePrograms",
				component: () =>
					import("./views/pages/statePrograms/StatePrograms.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Гос. программы",
							disabled: true,
						},
					],
				},
			},
			{
				path: "statePrograms/slices/:id",
				name: "Slices",
				component: () => import("./views/pages/statePrograms/Slices.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Гос. программы",
							href: "/#/pages/statePrograms/",
							disabled: false,
						},
						{
							text: "Срезы",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "statePrograms/slices/:id/create",
				name: "SlicesCreate",
				component: () => import("./views/pages/statePrograms/SlicesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Гос. программы",
							href: "/#/pages/statePrograms/",
							disabled: false,
						},
						{
							text: "Срезы",
							href: "/#/pages/statePrograms/slices/:id",
							disabled: false,
						},
						{
							text: "",
							disabled: true,
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path: "statePrograms/slices/:id/update/",
				name: "SlicesUpdate",
				component: () => import("./views/pages/statePrograms/SlicesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Гос. программы",
							href: "/#/pages/statePrograms/",
							disabled: false,
						},
						{
							text: "Срезы",
							href: "/#/pages/statePrograms/slices/:id",
							disabled: false,
						},
						{
							text: "",
							disabled: true,
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			//Maps
			{
				path: "maps/update/:id",
				name: "MapsUpdate",
				component: () => import("./views/pages/maps/MapsForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Регионы",
							disabled: false,
							href: "/#/pages/regions",
						},
						{
							text: "Карта региона",
							disabled: true,
						},
					],
				},
			},
			//Indicators
			{
				path: "indicators",
				name: "Indicators",
				component: () => import("./views/pages/indicators/Indicators.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicators/regions/:id",
				name: "IndicatorsRegion",
				component: () =>
					import("./views/pages/indicators/IndicatorsRegion.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: false,
							href: "/#/pages/indicators",
						},
						{
							text: "По регионам",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicators/federalPassport/:id",
				name: "IndicatorsFederalForm",
				component: () =>
					import("./views/pages/indicators/IndicatorsFederalForm.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: false,
							href: "/#/pages/indicators",
						},
						{
							text: "Федеральный паспорт",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicators/regionalPassports/:id",
				name: "IndicatorsRegionalPassports",
				component: () =>
					import("./views/pages/indicators/IndicatorsRegionalPassports.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: false,
							href: "/#/pages/indicators",
						},
						{
							text: "Региональные паспорта",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicators/regionalPassports/:id/regionalPassportForm/",
				name: "IndicatorsRegionalCreate",
				component: () =>
					import("./views/pages/indicators/IndicatorsRegionalForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: false,
							href: "/#/pages/indicators",
						},
						{
							text: "Региональные паспорта",
							href: "/#/pages/indicators/regionalPassports/:id",
							disabled: false,
						},
						{
							text: "",
							disabled: true,
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path:
					"indicators/regionalPassports/:id/regionalPassportForm/:passport_id",
				name: "IndicatorsRegionalUpdate",
				component: () =>
					import("./views/pages/indicators/IndicatorsRegionalForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Показатели",
							disabled: false,
							href: "/#/pages/indicators",
						},
						{
							text: "Региональные паспорта",
							href: "/#/pages/indicators/regionalPassports/:id",
							disabled: false,
						},
						{
							text: "",
							disabled: true,
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicatorsTables",
				name: "IndicatorsTables",
				component: () =>
					import("./views/pages/indicators/IndicatorsTables.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{
							text: "Таблицы показателей",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicatorsTables/create",
				name: "IndicatorsTablesCreate",
				component: () =>
					import("./views/pages/indicators/IndicatorsTablesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Таблицы показателей",
							disabled: false,
							href: "/#/pages/indicatorsTables",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path: "indicatorsTables/update/:id",
				name: "IndicatorsTablesUpdate",
				component: () =>
					import("./views/pages/indicators/IndicatorsTablesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Таблицы показателей",
							disabled: false,
							href: "/#/pages/indicatorsTables",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			//Templates
			{
				path: "templates",
				name: "Templates",
				component: () => import("./views/pages/templates/Templates.vue"),
				meta: {
					requiresAuth: true,
					adminOnly: true,
					breadcrumb: [
						{
							text: "Шаблоны",
							disabled: true,
						},
					],
				},
			},
			{
				path: "templates/update/:id",
				name: "TemplatesUpdate",
				component: () => import("./views/pages/templates/TemplatesForm.vue"),
				meta: {
					requiresAuth: true,
					adminOnly: true,
					isUpdate: true,
					breadcrumb: [
						{
							text: "Шаблоны",
							disabled: false,
							href: "/#/pages/templates",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
						{
							text: "",
							disabled: true,
						},
					],
				},
			},
			{
				path: "templates/create",
				name: "TemplatesCreate",
				component: () => import("./views/pages/templates/TemplatesForm.vue"),
				meta: {
					requiresAuth: true,
					adminOnly: true,
					isUpdate: false,
					breadcrumb: [
						{
							text: "Шаблоны",
							disabled: false,
							href: "/#/pages/templates",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			/*
			//DataTables
			{
				path: "dataTables",
				name: "DataTables",
				component: () => import("./views/pages/data-tables/DataTables.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						
						{
							text: "Таблицы",
							disabled: true,
							href: "/#/pages/dataTables",
						},
					],
				},
			},
			{
				path: "dataTablesCreate",
				name: "WidgetsDataTableCreate",
				component: () => import("./views/pages/data-tables/DataTablesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						
						{
							text: "Таблицы",
							disabled: false,
							href: "/#/pages/dataTables",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path: "dataTablesUpdate/:id",
				name: "WidgetsDataTableUpdate",
				component: () => import("./views/pages/data-tables/DataTablesForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						
						{
							text: "Таблицы",
							disabled: false,
							href: "/#/pages/dataTables",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
					],
				},
			},
			//Secondary
			{
				path: "secondary",
				name: "Secondary",
				component: () => import("./views/pages/secondary/Secondary.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: [
						
						{
							text: "Доп. таблицы",
							disabled: true,
							href: "/#/pages/secondary",
						},
					],
				},
			},
			{
				path: "secondaryCreate",
				name: "SecondaryCreate",
				component: () => import("./views/pages/secondary/SecondaryForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: false,
					breadcrumb: [
						
						{
							text: "Доп. таблицы",
							disabled: false,
							href: "/#/pages/secondary",
						},
						{
							text: "Создание",
							disabled: true,
						},
					],
				},
			},
			{
				path: "secondaryUpdate/:id",
				name: "SecondaryUpdate",
				component: () => import("./views/pages/secondary/SecondaryForm.vue"),
				meta: {
					requiresAuth: true,
					isUpdate: true,
					breadcrumb: [
						
						{
							text: "Доп. таблицы",
							disabled: false,
							href: "/#/pages/secondary",
						},
						{
							text: "Редактирование",
							disabled: true,
						},
					],
				},
			},
			*/
		],
	},
];
const router = new Router({
	routes,
});

router.beforeEach((to, from, next) => {
	let isPassed = false;
	let isAuth = false;
	//Проверка auth
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (localStorage.getItem("isAuth") == "true") {
			store.commit(AUTH_SET_TOKEN);
			isPassed = true;
		} else {
			isPassed = false;
		}
	} else {
		next();
		return;
	}
	//Проверка role
	if (isPassed) {
		if (to.matched.some((record) => record.meta.adminOnly)) {
			if (localStorage.getItem("role") == "SU") {
				isPassed = true;
			} else {
				isPassed = false;
			}
		}
	}
	if (isPassed) {
		next();
		return;
	} else {
		next("/");
		return;
	}
});

export default router;
