import {
	STATE_PROGRAM_REQUEST,
	STATE_PROGRAM_LIST_REQUEST,
	STATE_PROGRAM_UPDATE,
	STATE_PROGRAM_ERROR,
	STATE_PROGRAM_CREATE,
	STATE_PROGRAM_DELETE,
	STATE_PROGRAM_UPDATED,
	STATE_PROGRAM_LOADED,
	SLICE_REQUEST_DISTRICT,
  SLICE_UPDATE,
  SLICE_DELETE,
  SLICE_CREATE,
} from "../actions/stateProgram";
import axios from "axios";
import { StateProgram } from "@/models/StateProgram";
import qs from "qs";

const state = {
	status: "",
	table: null,
	slicesByDistrict: null,
	hasLoadedOnce: false,
};

const getters = {
	STATE_PROGRAM_TABLE: (state) => {
		return state.table;
	},
	SLICES_BY_REGION: (state) => {
		return state.slicesByDistrict;
	},
	STATE_PROGRAM_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	STATE_PROGRAM_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[STATE_PROGRAM_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(STATE_PROGRAM_REQUEST);
			axios
				.get<StateProgram[]>(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/List", {
					params: data,
				})
				.then((resp) => {
					commit(STATE_PROGRAM_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
	// [STATE_PROGRAM_LIST_REQUEST]: ({ commit }, data) => {
	// 	return new Promise((resolve, reject) => {
	// 		commit(STATE_PROGRAM_REQUEST);
	// 		axios
	// 			.get<StateProgram[]>(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/List", {
	// 				params: data,
	// 			})
	// 			.then((resp) => {
	// 				commit(STATE_PROGRAM_LOADED, resp);
	// 				resolve(resp);
	// 			})
	// 			.catch((err) => {
	// 				commit(STATE_PROGRAM_ERROR, err);
	// 				reject(err);
	// 			});
	// 	});
	// },
	[STATE_PROGRAM_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/add", item,
				{ headers: { 'Content-Type': 'form-data' }})
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
	[STATE_PROGRAM_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/update/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
	[STATE_PROGRAM_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/delete/" + id)
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
  },
  //Slice
	[SLICE_REQUEST_DISTRICT]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.get(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/GetSlicesByDistrict",
          { params: item.params })
				.then((resp) => {
					commit(SLICE_REQUEST_DISTRICT, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
  [SLICE_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/AddSlice", item.data,
          { headers: { 'Content-Type': 'form-data' }, params: item.params})
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
	[SLICE_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/updateSlice/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
	[SLICE_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/StatePrograms/deleteSlice/" + id)
				.then((resp) => {
					commit(STATE_PROGRAM_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(STATE_PROGRAM_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[STATE_PROGRAM_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[STATE_PROGRAM_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[STATE_PROGRAM_REQUEST]: (state) => {
		state.status = "loading";
	},
	[STATE_PROGRAM_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
	[SLICE_REQUEST_DISTRICT]: (state, resp) => {
		state.slicesByDistrict = resp;
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
