import {
	INDICATOR_REQUEST,
	INDICATOR_UPDATE,
	INDICATOR_ERROR,
	INDICATOR_CREATE,
	INDICATOR_DELETE,
	INDICATOR_UPDATED,
	INDICATOR_LOADED,
	INDICATOR_REGION_CU,
	INDICATOR_REGION_DELETE,
	INDICATOR_VERIFY,
	INDICATOR_REGIONAL_PASSPORT_UPDATE,
	INDICATOR_REGIONAL_PASSPORT_CREATE,
	INDICATOR_REGIONAL_PASSPORT_DELETE,
	INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE,
	INDICATOR_FEDERAL_PASSPORT_UPDATE,
	INDICATOR_REGIONAL_UPDATED,
	INDICATOR_FEDERAL_UPDATED,
} from "../actions/indicator";
import axios from "axios";
import { Indicator } from "@/models/Indicator";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
};

const getters = {
	INDICATOR_TABLE: (state) => {
		return state.table;
	},
	INDICATOR_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	INDICATOR_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[INDICATOR_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(INDICATOR_REQUEST);
			axios
				.get<Indicator[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/List", {
					params: data,
				})
				.then((resp) => {
					commit(INDICATOR_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_VERIFY]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get(process.env.VUE_APP_API_ENDPOINT + "/api/indicator/verify/" + data.id, {
					params: data.params,
				})
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/Add", item,
				{ headers: { 'Content-Type': 'form-data' }})
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/update/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGION_CU]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.post(
					process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/AddOrUpdateRegionValue",
					item,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGIONAL_PASSPORT_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.post(
					process.env.VUE_APP_API_ENDPOINT + "/api/indicator/RegionalPassport/"+item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(INDICATOR_REGIONAL_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGIONAL_PASSPORT_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.post(
					process.env.VUE_APP_API_ENDPOINT + "/api/indicator/RegionalPassport/",
					item,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(INDICATOR_REGIONAL_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGIONAL_PASSPORT_DELETE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.delete(
					process.env.VUE_APP_API_ENDPOINT + "/api/indicator/DeleteRegionalPassport/"+item.id
				)
				.then((resp) => {
					commit(INDICATOR_REGIONAL_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.delete(
					process.env.VUE_APP_API_ENDPOINT + "/api/indicator/DeleteRegionalPassportAttachment/"+item.id
				)
				.then((resp) => {
					commit(INDICATOR_REGIONAL_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_FEDERAL_PASSPORT_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.post(
					process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/FederalPassport/"+item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(INDICATOR_FEDERAL_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_REGION_DELETE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.delete(
					process.env.VUE_APP_API_ENDPOINT + "/api/indicator/DeleteRegionValueCol/" + item.indicator_id,
					{ params: item.params }
				)
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
	///api/indicator/DeleteRegionValueCol/{indicator_id}?year={year}
	[INDICATOR_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/Delete/" + id)
				.then((resp) => {
					commit(INDICATOR_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[INDICATOR_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[INDICATOR_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[INDICATOR_REQUEST]: (state) => {
		state.status = "loading";
	},
	[INDICATOR_UPDATED]: (state, resp) => {
		//console.log("resp data", resp.data);
		state.hasLoadedOnce = false;
	},
	[INDICATOR_REGIONAL_UPDATED]: (state, resp) => {
		state.hasLoadedOnce = false;
		let parsed = JSON.stringify(resp.data);
		localStorage.setItem("regional", parsed);
	},
	[INDICATOR_FEDERAL_UPDATED]: (state, resp) => {
		state.hasLoadedOnce = false;
		localStorage.setItem("federal", resp.data);
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
