import {
	TEMPLATE_REQUEST,
	TEMPLATE_UPDATE,
	TEMPLATE_ERROR,
	TEMPLATE_CREATE,
	TEMPLATE_DELETE,
	TEMPLATE_UPDATED,
	TEMPLATE_LOADED,
} from "../actions/template";
import axios from "axios";
import { Template } from "@/models/Template";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
};

const getters = {
	TEMPLATE_TABLE: (state) => {
		return state.table;
	},
	TEMPLATE_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	TEMPLATE_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[TEMPLATE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(TEMPLATE_REQUEST);
			axios
				.get<Template[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Template/List", {
					params: data,
				})
				.then((resp) => {
					commit(TEMPLATE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(TEMPLATE_ERROR, err);
					reject(err);
				});
		});
	},
	[TEMPLATE_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Template/Add", item,
				{ headers: { 'Content-Type': 'application/json' }})
				.then((resp) => {
					commit(TEMPLATE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(TEMPLATE_ERROR, err);
					reject(err);
				});
		});
	},
	[TEMPLATE_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Template/update/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'application/json' }}
				)
				.then((resp) => {
					commit(TEMPLATE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(TEMPLATE_ERROR, err);
					reject(err);
				});
		});
	},
	[TEMPLATE_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Template/Delete/" + id)
				.then((resp) => {
					commit(TEMPLATE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(TEMPLATE_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[TEMPLATE_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[TEMPLATE_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[TEMPLATE_REQUEST]: (state) => {
		state.status = "loading";
	},
	[TEMPLATE_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
