import {
	AUTH_REQUEST,
	AUTH_ERROR,
	AUTH_SUCCESS,
	AUTH_LOGOUT,
	AUTH_SET_ID,
	AUTH_SET_TOKEN,
	PEM_REQUEST,
	PEM_SUCCESS,
} from "../actions/auth";
import { AuthData } from "@/models/AuthData";
import axios from "axios";
import qs from "qs";

const state = {
	username: "",
	status: "",
	hasLoadedOnce: false,
	error: "",
	pem: "",
};

const getters = {
	AUTH_STATUS: (state) => state.status,
	//username: (state) => state.username,
	AUTH_ERROR: (state) => state.error,
	AUTH_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	PEM: (state) => {
		return state.pem;
	},
};

const actions = {
	[AUTH_REQUEST]: ({ commit, dispatch }, auth) => {
		return new Promise((resolve, reject) => {
			var data = qs.stringify(auth);
			axios
				.post<AuthData>(process.env.VUE_APP_API_ENDPOINT + "/api/Login", auth, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((resp) => {
					localStorage.setItem("username", resp.data.username);
					localStorage.setItem("access_token", resp.data.access_token);
					localStorage.setItem("isAuth", "true");
					commit(AUTH_SUCCESS, resp);
					//Устанавливаем default настройки для axios
					commit(AUTH_SET_TOKEN, resp);
					resolve(resp);
				})
				.catch((resp) => {
					commit(AUTH_ERROR, resp);
					reject(resp);
				});
		});
	},
	[PEM_REQUEST]: ({ commit, dispatch }, auth) => {
		return new Promise((resolve, reject) => {
			var data = qs.stringify(auth);
			axios
				.get<string>(process.env.VUE_APP_API_ENDPOINT + "/api/login?pem=true", {
					responseType: "text",
				})
				.then((resp) => {
					commit(PEM_SUCCESS, resp);
					resolve(resp);
				})
				.catch((resp) => {
					reject(resp);
				});
		});
	},
	[AUTH_LOGOUT]: ({ commit, dispatch }) => {
		return new Promise((resolve, reject) => {
			commit(AUTH_LOGOUT);
			console.log("logout");
			resolve(null);
		});
	},
};

const mutations = {
	[AUTH_REQUEST]: (state) => {
		state.status = "loading";
	},
	[AUTH_SUCCESS]: (state, resp) => {
		state.status = "success";
		state.hasLoadedOnce = true;
	},
	[PEM_SUCCESS]: (state, resp) => {
		state.status = "success";
		state.pem = resp.data;
	},
	[AUTH_ERROR]: (state, err) => {
		state.status = "error";
		console.log(err.response);
		if (typeof err.response != "undefined") state.error = err.response.status;
		else state.error = 0;
		state.hasLoadedOnce = true;
	},
	[AUTH_LOGOUT]: (state) => {
		delete axios.defaults.headers.common["token"];
		delete axios.defaults.headers.common["id"];
		localStorage.removeItem("username");
		localStorage.removeItem("access_token");
		localStorage.setItem("isAuth", "false");
	},
	[AUTH_SET_TOKEN]: (state) => {
		let bearer: string = "Bearer " + localStorage.getItem("access_token");
		axios.defaults.headers.common["Authorization"] = bearer;
	},
	[AUTH_SET_ID]: (state) => {
		//axios.defaults.headers.common["id"] = Vue.$cookies.get("id");
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
