export const STATE_PROGRAM_REQUEST = "STATE_PROGRAM_REQUEST";
export const STATE_PROGRAM_LIST_REQUEST = "STATE_PROGRAM_LIST_REQUEST";

export const STATE_PROGRAM_UPDATE = "STATE_PROGRAM_UPDATE";
export const STATE_PROGRAM_UPDATED = "STATE_PROGRAM_UPDATED";
export const STATE_PROGRAM_DELETE = "STATE_PROGRAM_DELETE";
export const STATE_PROGRAM_CREATE = "STATE_PROGRAM_CREATE";
export const STATE_PROGRAM_ERROR = "STATE_PROGRAM_ERROR";
export const STATE_PROGRAM_LOADED = "STATE_PROGRAM_LOADED";

export const SLICE_REQUEST_DISTRICT = "SLICE_REQUEST_DISTRICT";
export const SLICE_UPDATE = "SLICE_UPDATE";
export const SLICE_DELETE = "SLICE_DELETE";
export const SLICE_CREATE = "SLICE_CREATE";