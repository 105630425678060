import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import "./registerServiceWorker";
import dateFilter from "@/filters/date.filter";
import "leaflet/dist/leaflet.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import VueHtml2Canvas from "vue-html2canvas";
//const VueInputMask = require('vue-inputmask').default;
const VueMask = require("v-mask").default;

Vue.use(VueMask);
Vue.use(Donut);
Vue.use(VueHtml2Canvas);
Vue.config.productionTip = false;
//Vue.use(VueInputMask);
Vue.filter("date", dateFilter);

new Vue({
	vuetify,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
