import {
	WIDGET_DATA_TABLE_REQUEST,
	WIDGET_DATA_TABLE_UPDATE,
	WIDGET_DATA_TABLE_ERROR,
	WIDGET_DATA_TABLE_CREATE,
	WIDGET_DATA_TABLE_DELETE,
	WIDGET_DATA_TABLE_SUCCESS,
	WIDGET_DATA_TABLE_UPDATED,
	WIDGET_DATA_TABLE_LOADED,
} from "../actions/widgetDataTable";
import axios from "axios";
import { WidgetDataTable } from "@/models/Widget";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
	currentPage: 0,
	count: 0,
	exportPath: null,
};

const getters = {
	WIDGET_DATA_TABLE: (state) => {
		return state.table;
	},
	WIDGET_DATA_TABLE_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	WIDGET_DATA_TABLE_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[WIDGET_DATA_TABLE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(WIDGET_DATA_TABLE_REQUEST);
			axios
				.get<WidgetDataTable[]>(
					process.env.VUE_APP_API_ENDPOINT + "/api/Data/List",
					{
						params: data,
					}
				)
				.then((resp) => {
					commit(WIDGET_DATA_TABLE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_DATA_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_DATA_TABLE_CREATE]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Data/Add", data)
				.then((resp) => {
					commit(WIDGET_DATA_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_DATA_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_DATA_TABLE_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Data/update/" + item.id,
					data
				)
				.then((resp) => {
					commit(WIDGET_DATA_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_DATA_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_DATA_TABLE_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Data/Delete/" + id)
				.then((resp) => {
					commit(WIDGET_DATA_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_DATA_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[WIDGET_DATA_TABLE_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[WIDGET_DATA_TABLE_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[WIDGET_DATA_TABLE_REQUEST]: (state) => {
		state.status = "loading";
	},
	[WIDGET_DATA_TABLE_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
