export const INDICATOR_REQUEST = "INDICATOR_REQUEST";
export const INDICATOR_UPDATE = "INDICATOR_UPDATE";
export const INDICATOR_UPDATED = "INDICATOR_UPDATED";
export const INDICATOR_DELETE = "INDICATOR_DELETE";
export const INDICATOR_CREATE = "INDICATOR_CREATE";
export const INDICATOR_ERROR = "INDICATOR_ERROR";
export const INDICATOR_LOADED = "INDICATOR_LOADED";
export const INDICATOR_REGION_CU = "INDICATOR_REGION_CU";
export const INDICATOR_REGIONAL_PASSPORT_UPDATE = "INDICATOR_REGIONAL_PASSPORT_UPDATE";
export const INDICATOR_REGIONAL_PASSPORT_CREATE = "INDICATOR_REGIONAL_PASSPORT_CREATE";
export const INDICATOR_REGIONAL_PASSPORT_DELETE = "INDICATOR_REGIONAL_PASSPORT_DELETE";
export const INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE = "INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE";
export const INDICATOR_FEDERAL_PASSPORT_UPDATE = "INDICATOR_FEDERAL_PASSPORT_UPDATE";
export const INDICATOR_REGION_DELETE = "INDICATOR_REGION_DELETE";
export const INDICATOR_VERIFY = "INDICATOR_VERIFY";
export const INDICATOR_REGIONAL_UPDATED = "INDICATOR_REGIONAL_UPDATED";
export const INDICATOR_FEDERAL_UPDATED = "INDICATOR_FEDERAL_UPDATED";

