import {
	PAGE_REQUEST,
	PAGE_ERROR,
	PAGE_CU,
	PAGE_DELETE,
	PAGE_UPDATED,
	PAGE_LOADED,
} from "../actions/page";
import axios from "axios";
import { Page } from "@/models/Page";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
	currentPage: 0,
	count: 0,
};

const getters = {
	PAGE_TABLE: (state) => {
		return state.table;
	},
	PAGE_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	PAGE_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[PAGE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(PAGE_REQUEST);
			axios
				.get<Page[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Page/List", {
					params: data,
				})
				.then((resp) => {
					commit(PAGE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(PAGE_ERROR, err);
					reject(err);
				});
		});
	},
	[PAGE_CU]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(
					process.env.VUE_APP_API_ENDPOINT + "/api/Page/AddOrUpdate",
					item,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					commit(PAGE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(PAGE_ERROR, err);
					reject(err);
				});
		});
	},
	[PAGE_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Page/delete/" + id)
				.then((resp) => {
					commit(PAGE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(PAGE_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[PAGE_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[PAGE_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[PAGE_REQUEST]: (state) => {
		state.status = "loading";
	},
	[PAGE_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
