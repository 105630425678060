import {
	WIDGET_REQUEST,
	WIDGET_UPDATE,
	WIDGET_ERROR,
	WIDGET_CREATE,
	WIDGET_DELETE,
	WIDGET_VERIFY,
	WIDGET_UPDATED,
	WIDGET_LOADED,
} from "../actions/widget";
import axios from "axios";
import { Widget } from "@/models/Widget";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
};

const getters = {
	WIDGET_TABLE: (state) => {
		return state.table;
	},
	WIDGET_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	WIDGET_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[WIDGET_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(WIDGET_REQUEST);
			axios
				.get<Widget[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Widget/List", {
					params: data,
				})
				.then((resp) => {
					commit(WIDGET_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_VERIFY]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get(process.env.VUE_APP_API_ENDPOINT + "/api/widget/verify/" + data.id, {
					params: data.params,
				})
				.then((resp) => {
					commit(WIDGET_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Widget/Add", item,
				{ headers: { 'Content-Type': 'application/json' }})
				.then((resp) => {
					commit(WIDGET_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Widget/update/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'application/json' }}
				)
				.then((resp) => {
					commit(WIDGET_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_ERROR, err);
					reject(err);
				});
		});
	},
	[WIDGET_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Widget/Delete/" + id)
				.then((resp) => {
					commit(WIDGET_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(WIDGET_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[WIDGET_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[WIDGET_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[WIDGET_REQUEST]: (state) => {
		state.status = "loading";
	},
	[WIDGET_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
