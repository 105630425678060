import {
	INDICATOR_TABLE_REQUEST,
	INDICATOR_TABLE_CU,
	INDICATOR_TABLE_ERROR,
	INDICATOR_TABLE_DELETE,
	INDICATOR_TABLE_UPDATED,
	INDICATOR_TABLE_LOADED,
	INDICATOR_TABLE_REGION_CU,
	INDICATOR_TABLE_VERIFY,
} from "../actions/indicatorTable";
import axios from "axios";
import { IndicatorTable } from "@/models/Indicator";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
};

const getters = {
	INDICATOR_TABLE_TABLE: (state) => {
		return state.table;
	},
	INDICATOR_TABLE_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	INDICATOR_TABLE_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[INDICATOR_TABLE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(INDICATOR_TABLE_REQUEST);
			axios
				.get<IndicatorTable[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/tableList", {
					params: data,
				})
				.then((resp) => {
					commit(INDICATOR_TABLE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_TABLE_VERIFY]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get(process.env.VUE_APP_API_ENDPOINT + "/api/indicator/VerifyTable/" + data.id, {
					params: data.params,
				})
				.then((resp) => {
					commit(INDICATOR_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_TABLE_CU]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/AddOrUpdateTable", item,
				{ headers: { 'Content-Type': 'application/json' }})
				.then((resp) => {
					commit(INDICATOR_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
	[INDICATOR_TABLE_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Indicator/DeleteTable/" + id)
				.then((resp) => {
					commit(INDICATOR_TABLE_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(INDICATOR_TABLE_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[INDICATOR_TABLE_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[INDICATOR_TABLE_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[INDICATOR_TABLE_REQUEST]: (state) => {
		state.status = "loading";
	},
	[INDICATOR_TABLE_UPDATED]: (state, resp) => {
		console.log("resp data - indicatorsTablesToEdit:", resp.data);
		const parsed = JSON.stringify(resp.data);
		localStorage.setItem("indicatorsTablesToEdit", parsed);
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
