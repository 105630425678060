






import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
	private clipped: boolean = true;
	private drawer: boolean = true;
	private miniVariant: boolean = false;
	private right: boolean = true;
	private title: string = "";
	private items = [];
}
