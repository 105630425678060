
export const WIDGET_TYPE_REQUEST = "WIDGET_TYPE_REQUEST";
export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const WIDGET_TYPE_LOADED = "WIDGET_TYPE_LOADED";
export const USER_ROLE_LOADED = "USER_ROLE_LOADED";
//Region
export const REGION_REQUEST = "REGION_REQUEST";
export const REGION_LOADED = "REGION_LOADED";
export const REGION_UPDATE = "REGION_UPDATE";
export const REGION_UPDATED = "REGION_UPDATED";
export const REGION_DELETE = "REGION_DELETE";
export const REGION_CREATE = "REGION_CREATE";
export const REGION_SUCCESS = "REGION_SUCCESS";
export const REGION_ERROR = "REGION_ERROR";
export const MAP_CREATE = "MAP_CREATE";
//District
export const DISTRICT_UPDATE = "DISTRICT_UPDATE";
export const DISTRICT_CREATE = "DISTRICT_CREATE";
export const DISTRICT_DELETE = "DISTRICT_DELETE";