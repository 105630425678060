import {
	USER_REQUEST,
	USER_UPDATE,
	USER_ERROR,
	USER_CREATE,
	USER_DELETE,
	USER_UPDATED,
	USER_LOADED,
} from "../actions/user";
import axios from "axios";
import { User } from "@/models/User";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
};

const getters = {
	USER_TABLE: (state) => {
		return state.table;
	},
	USER_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	USER_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[USER_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(USER_REQUEST);
			axios
				.get<User[]>(process.env.VUE_APP_API_ENDPOINT + "/api/User/List", {
					params: data,
				})
				.then((resp) => {
					commit(USER_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(USER_ERROR, err);
					reject(err);
				});
		});
	},
	[USER_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/User/Add",
					item,
					{ headers: { 'Content-Type': 'multipart/form-data' }})
				.then((resp) => {
					commit(USER_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(USER_ERROR, err);
					reject(err);
				});
		});
	},
	[USER_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/User/update/" + item.id,
					item.data,
					{headers: {'Content-Type': 'multipart/form-data'}}
				)
				.then((resp) => {
					commit(USER_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(USER_ERROR, err);
					reject(err);
				});
		});
	},
	[USER_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/User/delete/" + id)
				.then((resp) => {
					commit(USER_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(USER_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[USER_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[USER_ERROR]: (state, err) => {
		console.log(err);
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[USER_REQUEST]: (state) => {
		state.status = "loading";
	},
	[USER_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
