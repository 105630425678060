import {
	REGION_REQUEST,
	WIDGET_TYPE_REQUEST,
	USER_ROLE_REQUEST,
	REGION_LOADED,
	WIDGET_TYPE_LOADED,
	USER_ROLE_LOADED,
	REGION_CREATE,
	REGION_DELETE,
	REGION_UPDATE,
	DISTRICT_CREATE,
	DISTRICT_DELETE,
	DISTRICT_UPDATE,
	REGION_UPDATED,
	MAP_CREATE
} from "../actions/main";
import axios from "axios";
import qs from "qs";

const state = {
	regionTable: null,
	widgetTypeTable: null,
	userTypeTable: null,
	regionLoaded: false,
	widgetTypeLoaded: false,
	userTypeLoaded: false,
};

const getters = {
	REGION_TABLE: (state) => {
		return state.regionTable;
	},
	WIDGET_TYPE_TABLE: (state) => {
		return state.widgetTypeTable;
	},
	USER_ROLE_TABLE: (state) => {
		return state.userTypeTable;
	},
	REGION_LOADED: (state) => {
		return state.regionLoaded;
	},
	WIDGET_TYPE_LOADED: (state) => {
		return state.widgetTypeLoaded;
	},
	USER_ROLE_LOADED: (state) => {
		return state.userTypeLoaded;
	},
};

const actions = {
	[REGION_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get<any[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Dictionary/GetRegions", {
					params: data,
				})
				.then((resp) => {
					commit(REGION_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[WIDGET_TYPE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get<any[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Dictionary/GetWidgetTypes", {
					params: data,
				})
				.then((resp) => {
					commit(WIDGET_TYPE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[USER_ROLE_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			axios
				.get<any[]>(process.env.VUE_APP_API_ENDPOINT + "/api/Dictionary/GetUserRoles", {
					params: data,
				})
				.then((resp) => {
					commit(USER_ROLE_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[REGION_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/Region/add",
					item,
					{ headers: { 'Content-Type': 'form-data' }})
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[REGION_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Region/update/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[REGION_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Region/Delete/" + id)
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[DISTRICT_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/region/addDistrict",
					item.data,
					{ headers: { 'Content-Type': 'form-data' }, params: item.params})
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[DISTRICT_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/region/updateDistrict/" + item.id,
					item.data,
					{ headers: { 'Content-Type': 'form-data' }}
				)
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[DISTRICT_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.VUE_APP_API_ENDPOINT + "/api/Region/Delete/" + id)
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	[MAP_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			var id = localStorage.getItem("regionId");
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/region/addMap/"+id, item, {headers: {'Content-Type': 'form-data'}})
				.then((resp) => {
					commit(REGION_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};
const mutations = {
	[REGION_LOADED]: (state, resp) => {
		state.regionTable = resp;
		state.regionLoaded = true;
	},
	[WIDGET_TYPE_LOADED]: (state, resp) => {
		state.widgetTypeTable = resp;
		state.widgetTypeLoaded = true;
	},
	[USER_ROLE_LOADED]: (state, resp) => {
		state.userTypeTable = resp;
		state.userTypeLoaded = true;
	},
	[REGION_UPDATED]: (state) => {
		state.regionLoaded = false;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
