import {
	SECONDARY_REQUEST,
	SECONDARY_UPDATE,
	SECONDARY_ERROR,
	SECONDARY_CREATE,
	SECONDARY_DELETE,
	SECONDARY_UPDATED,
	SECONDARY_LOADED,
} from "../actions/secondary";
import axios from "axios";
import { Secondary } from "@/models/Secondary";
import qs from "qs";

const state = {
	status: "",
	table: null,
	hasLoadedOnce: false,
	currentPage: 0,
	count: 0,
};

const getters = {
	SECONDARY_TABLE: (state) => {
		return state.table;
	},
	SECONDARY_LOADED: (state) => {
		return state.hasLoadedOnce;
	},
	SECONDARY_STATUS: (state) => {
		return state.status;
	},
};

const actions = {
	[SECONDARY_REQUEST]: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			commit(SECONDARY_REQUEST);
			axios
				.get<Secondary[]>(
					process.env.VUE_APP_API_ENDPOINT + "/api/Secondary/List",
					{
						params: data,
					}
				)
				.then((resp) => {
					commit(SECONDARY_LOADED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(SECONDARY_ERROR, err);
					reject(err);
				});
		});
	},
	[SECONDARY_CREATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(controller);
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + "/api/secondary/add", item, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((resp) => {
					commit(SECONDARY_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(SECONDARY_ERROR, err);
					reject(err);
				});
		});
	},
	[SECONDARY_UPDATE]: ({ commit }, item) => {
		return new Promise((resolve, reject) => {
			//var data = qs.stringify(item.data);
			axios
				.put(
					process.env.VUE_APP_API_ENDPOINT + "/api/Secondary/update/" + item.id,
					item, {
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					commit(SECONDARY_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(SECONDARY_ERROR, err);
					reject(err);
				});
		});
	},
	[SECONDARY_DELETE]: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(
					process.env.VUE_APP_API_ENDPOINT + "/api/Secondary/delete/" + id
				)
				.then((resp) => {
					commit(SECONDARY_UPDATED, resp);
					resolve(resp);
				})
				.catch((err) => {
					commit(SECONDARY_ERROR, err);
					reject(err);
				});
		});
	},
};

const mutations = {
	[SECONDARY_LOADED]: (state, resp) => {
		state.status = "success";
		state.table = resp;
		state.hasLoadedOnce = true;
	},
	[SECONDARY_ERROR]: (state) => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[SECONDARY_REQUEST]: (state) => {
		state.status = "loading";
	},
	[SECONDARY_UPDATED]: (state) => {
		state.hasLoadedOnce = false;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
