import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import main from "@/store/modules/main";
import widget from "@/store/modules/widget";
import widgetData from "@/store/modules/widgetDataTable";
import user from "@/store/modules/user";
import secondary from "@/store/modules/secondary";
import template from "@/store/modules/template";
import indicator from "@/store/modules/indicator";
import indicatorTable from "@/store/modules/indicatorTable";
import stateProgram from "@/store/modules/stateProgram";
import page from "@/store/modules/page";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		main,
		auth,
		widget,
		widgetData,
		user,
		secondary,
		template,
		indicator,
		indicatorTable,
		stateProgram,
		page,
	},
});
